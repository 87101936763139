<template>
  <div class="detail-share-post">
    <v-header title="分享海报">
    </v-header>
    <div class="share-post">
      <img :src="sharePostUrl" @load="loadSuccess" v-if="shareMode && shareMode.length" @error="loadError">
    </div>
    <div class="operation">
      <van-radio-group :value="shareMode" class="mode-radio-group" @input="switchShareMode($event)">
        <van-radio name="preview">多图版</van-radio>
        <van-radio name="cover">双图版</van-radio>
      </van-radio-group>
      <div class="operation-text">
        <div>长按上图，保存图片或发送给朋友~</div>
        <div style="display: flex;align-items: center;gap: 4px;"
             @click="updateTs">
          <i class="iconfont icon-change"></i>
          换图
        </div>
        <!--<div class="gray">新用户通过识别此二维码首次登录,</div>-->
        <!--<div class="gray">双方都会获得{{yanzhiFilter(config.recommendAwardYanzhi)}}奖励严值哦~上不封顶~</div>-->
      </div>
    </div>
  </div>
</template>

--------------------------------------
<script>
import {mapGetters} from 'vuex'
import {taskV2Api} from "../../api/v2/taskV2Api";
import {timeRandomId} from "../../config/util";

export default {
  data() {
    return {
      shareMode: '',
      ts: Date.now(),
      hashNo: timeRandomId()
    }
  },

  mounted() {
    this.switchShareMode('preview');
  },

  computed: {
    ...mapGetters(['userInfo']),

    sharePostUrl() {
      if (!this.shareMode || !this.shareMode.length) {
        return '';
      }
      const planId = this.$route.params.id;
      const mode = this.shareMode;
      const applet = 'h5';
      let url = window.location.href
        .replace(window.location.search, '');
      if (this.$route.query.group) {
        url = url.replace(window.location.pathname, '') + '/casePlanDetail/' + planId;
      } else {
        url = url.replace(window.location.pathname, '') + '/caseDetail/' + planId;
      }
      if (this.userInfo && this.userInfo.id) {
        url += '?shareUserId=' + this.userInfo.id;
      }

      if (this.$route.query.group) {
        return `/api/applet/v2/search-svr/planListSharePost/${planId}.jpg?hashNo=${this.hashNo}&mode=${mode}&applet=${applet}&url=${url}&ts=${this.ts}`;
      } else {
        return `/api/applet/v2/search-svr/planSharePost/${planId}.jpg?hashNo=${this.hashNo}&mode=${mode}&applet=${applet}&url=${url}&ts=${this.ts}`;
      }
    }
  },

  methods: {
    updateTs() {
      this.ts = Date.now()
      this.switchShareMode(this.shareMode)
    },
    // 切换分享模式
    switchShareMode(mode) {
      this.$loading('努力生成中~');
      setTimeout(() => {
        this.shareMode = mode;
      }, 500)
    },

    loadSuccess() {
      this.$loading.close();
    },

    loadError() {
      this.$loading.close();
      this.$toast.error('失败');
    },
  }
}
</script>

--------------------------------------
<style lang="less" scoped>
.detail-share-post {
  background: #f2f4f7;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.operation {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100px;
  background: #fff;
  padding-top: 30px;
  /*border-top-left-radius: 30px;*/
  /*border-top-right-radius: 30px;*/
  /*border-top: 1px solid #e3e3e3 ;*/
}

.share-post {
  position: fixed;
  top: 40px;
  bottom: 125px;
  width: 100vw;
  overflow-y: scroll;

  img {
    width: 100vw;
  }
}

.mode-radio-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  color: #aaa;

  .active {
    color: #02A9F1;
  }
}

.operation-text {
  padding-top: 25px;
  font-size: 12px;
  color: #151d36;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
</style>
